@import "../../styles/Palette.module.scss";
@import "../../styles/Variables.module.scss";

.autocompleteItem {
  position: relative;
  color: $pqTextColor;
  display: flex;
  align-items: center;
  width: 100%;

  &-blue {
    .autocompleteItem {
      &__icon {
        color: $pqBlue;
      }
      &__after {
        color: $pqBlue;
      }
    }
  }

  &-green {
    .autocompleteItem {
      &__icon {
        color: $pqGreen;
      }
      &__after {
        color: $pqGreen;
      }
    }
  }

  &:hover {
    .autocompleteItem {
      &__after {
        right: 0;
        opacity: 1;
      }
    }
  }

  &__icon,
  &__image {
    margin-right: $baseSpacing;
  }

  &__image {
    line-height: 0;
    text-align: center;

    img {
      max-height: 32px;
    }
  }

  &__value {
    margin-right: 5%;
    flex: 1;
  }

  &__after {
    position: relative;
    right: 5%;
    opacity: 0;
    transition: right $baseAnimationDuration $baseAnimationTimingFunction,
      opacity $baseAnimationDuration $baseAnimationTimingFunction;
  }
}
