@import "../../styles/Variables.module.scss";
@import "../../styles/Palette.module.scss";
@import "../../styles/MediaQueries.module.scss";
@import "../../styles/Mixin.module.scss";
@import "../../styles/Font.module.scss";


$lineClampVal: 3;

.offersCarousel {
  width: 100%;
  padding: 20px 0 20px 20px;

  &_seeMore {
    flex-shrink: 0;
    height: 200px;
    width: 160px;
    color: $pqBlue;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-weight: bold;

    &_text {
      margin-right: $baseSpacing;
      font-size: 1.25em;
      cursor: pointer;
    }
  }
}
