@import "../../styles/Palette.module.scss";
@import "../../styles/Variables.module.scss";
@import "../../styles/MediaQueries.module.scss";

.main {
  display: block;
  background-color: $pqBgPrimaryColor;
  padding: $normalSpacing 0;

  @include mediumUp() {
    padding-top: 0;
    padding-bottom: $normalSpacing;
  }

  &--light {
    background-color: $pqBgSecondaryColor;
  }

  &__content {
    max-width: $contentMaxWidth;
    margin: auto;
  }
}
