$basefontFamily: "Roboto";

$smallestfontSize: 10px;
$smallfontSize: 13px;
$basefontSize: 16px;
$mediumfontSize: 20px;
$largefontSize: 25px;
$largestfontSize: 32px;
$bigfontSize: 40px;

$normalLineHeight: 15px;
$baseLineHeight: 24px;
