@import "../../styles/Palette.module.scss";
@import "../../styles/Variables.module.scss";
@import "../../styles/MediaQueries.module.scss";
@import "../../styles/Font.module.scss";

.dialogPaper {
  margin: $baseSpacing 0 !important; //No choice but to use important here :(
  align-self: flex-start;
  min-height: 250px;
  width: 100%;
  max-width: 95vw;
}
.container {
  //here we should have been able to remove the margin
  overflow: scroll;
}
.locationDialog {
  min-width: 500px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  margin: 0;

  @include mediumDown() {
    min-width: 300px;
  }

  &__heading {
    text-align: center;
    padding: $normalSpacing;

    h2 {
      font-size: 1.2rem;
      margin-bottom: $baseSpacing;
    }

    h3 {
      font-weight: normal;
      margin-bottom: 0;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: $mediumSpacing $normalSpacing;
  }
  &_cross {
    margin: 30px 20px 0 0;
    position: absolute;
    right: 0;
    color: $pqMediumGray;
    font-size: $mediumfontSize;
  }
}
