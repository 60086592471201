@import "../../styles/Palette.module.scss";
@import "../../styles/Variables.module.scss";
@import "../../styles/MediaQueries.module.scss";
@import "../../styles/Font.module.scss";

.breadCrumbContainer {
  display: flex;
  color: black;
  width: 100%;
  background-color: $pqLightestGray;
  padding: 8px 0 !important;
  font-family: $basefontFamily, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: $pqMediumGray;
}
.breadCrumbItem {
  border: none;
  background-color: inherit;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: $pqMediumGray;
}
.breadCrumbButton {
  border: none;
  background-color: inherit;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  font-family: $basefontFamily, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: $pqMediumGray;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
