@import "../../styles/FontImport.module.scss";

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.pqIcon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &-rotate {
    animation: rotation 2s infinite linear;
  }
}

.icon-bulb:before {
  content: "\e954";
}

.icon-home:before {
  content: "\e953";
}
.icon-add:before {
  content: "\e951";
}
.icon-bin:before {
  content: "\e94b";
}
.icon-x1:before {
  content: "\e94a";
}
.icon-external-link:before {
  content: "\e952";
}
.icon-bin:before {
  content: "\e94b";
}
.icon-x1:before {
  content: "\e94a";
}
.icon-view-hide:before {
  content: "\e94d";
}
.icon-view-show:before {
  content: "\e94e";
}
.icon-envelope-solid:before {
  content: "\e950";
}
.icon-leanpub:before {
  content: "\e94f";
}
.icon-envelope:before {
  content: "\e94c";
}
.icon-exclamation-triangle:before {
  content: "\e943";
}
.icon-circle-o-notch:before {
  content: "\e942";
}
.icon-clock-o:before {
  content: "\e941";
}
.icon-calendar:before {
  content: "\e940";
}
.icon-search:before {
  content: "\e93f";
}
.icon-check:before {
  content: "\e93e";
}
.icon-paper-plane-solid:before {
  content: "\e944";
}
.icon-reply:before {
  content: "\e945";
}
.icon-x:before {
  content: "\e946";
}
.icon-paper-plane-light:before {
  content: "\e947";
}
.icon-facebook:before {
  content: "\e949";
}
.icon-google:before {
  content: "\e948";
}
.icon-location-arrow:before {
  content: "\e93b";
}
.icon-map-marker-alt:before {
  content: "\e93c";
}
.icon-dot-circle:before {
  content: "\e93d";
}
.icon-archive:before {
  content: "\e900";
}
.icon-baby:before {
  content: "\e901";
}
.icon-bars:before {
  content: "\e902";
}
.icon-bell:before {
  content: "\e903";
}
.icon-broom:before {
  content: "\e904";
}
.icon-bug:before {
  content: "\e905";
}
.icon-car-alt:before {
  content: "\e906";
}
.icon-chevron-circle-down:before {
  content: "\e907";
}
.icon-chevron-circle-left:before {
  content: "\e908";
}
.icon-chevron-circle-right:before {
  content: "\e909";
}
.icon-chevron-circle-up:before {
  content: "\e90a";
}
.icon-chevron-down:before {
  content: "\e90b";
}
.icon-chevron-left:before {
  content: "\e90c";
}
.icon-chevron-right:before {
  content: "\e90d";
}
.icon-chevron-up:before {
  content: "\e90e";
}
.icon-crosshairs:before {
  content: "\e90f";
}
.icon-desktop:before {
  content: "\e910";
}
.icon-dollar-sign:before {
  content: "\e911";
}
.icon-edit:before {
  content: "\e93a";
}
.icon-egg:before {
  content: "\e912";
}
.icon-euro-sign:before {
  content: "\e913";
}
.icon-folder-open:before {
  content: "\e914";
}
.icon-futbol:before {
  content: "\e915";
}
.icon-gamepad:before {
  content: "\e916";
}
.icon-ghost:before {
  content: "\e917";
}
.icon-gift:before {
  content: "\e918";
}
.icon-glass-martini-alt:before {
  content: "\e919";
}
.icon-glass-martini:before {
  content: "\e91a";
}
.icon-glasses:before {
  content: "\e91b";
}
.icon-globe-africa:before {
  content: "\e91c";
}
.icon-graduation-cap:before {
  content: "\e91d";
}
.icon-hat-wizard:before {
  content: "\e91e";
}
.icon-heart:before {
  content: "\e91f";
}
.icon-heartbeat:before {
  content: "\e920";
}
.icon-leaf:before {
  content: "\e921";
}
.icon-magic:before {
  content: "\e922";
}
.icon-mitten:before {
  content: "\e923";
}
.icon-mobile-alt:before {
  content: "\e924";
}
.icon-newspaper:before {
  content: "\e925";
}
.icon-paperclip:before {
  content: "\e926";
}
.icon-paw:before {
  content: "\e927";
}
.icon-pencil-alt:before {
  content: "\e928";
}
.icon-pencil-ruler:before {
  content: "\e929";
}
.icon-phone-alt:before {
  content: "\e92a";
}
.icon-piggy-bank:before {
  content: "\e92b";
}
.icon-shopping-bag:before {
  content: "\e92c";
}
.icon-shopping-cart:before {
  content: "\e92d";
}
.icon-sliders-h:before {
  content: "\e92e";
}
.icon-tags:before {
  content: "\e92f";
}
.icon-theater-masks:before {
  content: "\e930";
}
.icon-tree:before {
  content: "\e931";
}
.icon-utensil-spoon:before {
  content: "\e932";
}
.icon-utensils:before {
  content: "\e933";
}
.icon-bell1:before {
  content: "\e934";
}
.icon-folder-open1:before {
  content: "\e935";
}
.icon-facebook-f:before {
  content: "\e936";
}
.icon-pinterest-p:before {
  content: "\e937";
}
.icon-snapchat-ghost:before {
  content: "\e938";
}
.icon-twitter:before {
  content: "\e939";
}
