@import "../../styles/Palette.module.scss";

.menuListDivider {
  hr {
    margin: 0;
    border-top: 1px solid $pqBaseBorderColor;
    border-bottom: 0;
    border-right: 0;
    border-left: 0;
  }
}
