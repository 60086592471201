@import "../../styles/Mixin.module.scss";
@import "../../styles/Variables.module.scss";
@import "../../styles/Font.module.scss";

.menuListItem {
  &:first-child {
    padding-top: 5px;
  }

  .item {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: $normalSpacing $baseSpacing * 3;
    transition: background-color $baseAnimationDuration
      $baseAnimationTimingFunction;

    a {
      @include anchorColor($pqTextColor, $pqTextColor);
    }

    &:hover {
      background-color: $pqBgPrimaryColor;
    }

    &_icon {
      margin-right: $normalSpacing;
      font-size: $mediumfontSize;
      line-height: 0;
      i[class*="pqIcon"] {
        font-size: 20px;
      }
    }

    &_label {
      color: $pqTextColor;
    }
  }
}
