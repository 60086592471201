@import "../../styles/Palette.module.scss";
@import "../../styles/Variables.module.scss";
@import "../../styles/MediaQueries.module.scss";
@import "../../styles/Font.module.scss";

.card {
  width: 100%;
  margin: 16px 0;

  &_header {
    display: flex;
    align-items: center;
    background-color: $pqBgPrimaryColor;
    color: $pqTextColor;
    text-transform: uppercase;
    font-weight: 700;
    padding: 6px 16px 6px 6px;

    @include largeUp() {
      padding: 10px 30px 10px 0px;
    }

    .title {
      flex: 1;
      font-size: $basefontSize;
      @include largeUp() {
        font-size: $mediumfontSize;
      }
    }

    .logo {
      width: 40px;
      line-height: 0;
      margin-left: $baseSpacing;

      @include mediumUp() {
        display: none;
      }
    }
  }

  &_actions {
    width: auto;
    .action {
      cursor: pointer;
      i[class*="pqIcon"] {
        font-size: 20px;
      }
    }
  }

  &_body {
    background-color: $pqBgSecondaryColor;
    position: relative;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05), 0 3px 10px rgba(0, 0, 0, 0.1);

    @include largeUp() {
      border-radius: $baseBorderRadiusDefault;
      border-bottom: 1px solid $pqBaseBorderColor;
    }

    &-separator {
      @include largeUp() {
        border-top-left-radius: $baseBorderRadiusDefault;
      }
      height: 5px;
      width: 150px;
      background-color: $pqPrimary;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }

  &-collapsable {
    .card {
      &_header {
        cursor: pointer;
      }
      &_body {
        max-height: 500px;
        overflow: auto;
        transition: max-height $baseAnimationDuration
          $baseAnimationTimingFunction;
      }
    }
  }

  &-collapsed {
    .card {
      &_body {
        max-height: 0;
      }
    }
  }
}
