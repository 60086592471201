.pqImg {
  width: 100%;
  height: 100%;

  &_picture {
    object-fit: contain;
    object-position: center center;
    width: 100%;
    max-width: 100%;
    height: auto;
    display: flex;

    &_loader {
      width: 100%;
      max-width: 60px;
      height: auto;
      object-fit: contain;
      object-position: center center;
    }
  }
}
