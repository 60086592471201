@import "../../styles/Variables.module.scss";
@import "../../styles/Palette.module.scss";
@import "../../styles/MediaQueries.module.scss";

.navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $pqBgPrimaryColor;
  box-shadow: 0 0 0 transparent;
  transition: box-shadow $baseAnimationDuration $baseAnimationTimingFunction;

  @include mediumUp() {
    height: $navHeight + 20px;
    padding: 0 $normalSpacing;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 250;
  }

  & ~ main {
    @include mediumUp() {
      margin-top: $navHeight + 20px;
    }
  }

  // &-collapsed {
  // }

  &_content {
    height: 100%;
    flex: 0 1 $contentMaxWidth;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
  }

  &_header {
    display: flex;
    align-items: center;
    background-color: $pqBgPrimaryColor;
    width: 100%;

    @include small() {
      height: $navBaseHeight;
      padding: $spacingHeader;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 250;
    }

    @include mediumUp() {
      flex-wrap: nowrap;
      flex: 0 0 40%;
    }

    &_hamburger {
      width: 20px;
      margin-right: $basePadding;
      cursor: pointer;

      i[class*="pqIcon"] {
        color: $pqTextColor;
        font-size: 21px;
      }

      @include small() {
        display: none;
      }
    }

    &_logo {
      display: block;
      flex: 0 1 135px;

      @include mediumUp() {
        flex: 0 1 200px;
      }

      margin-right: $normalSpacing;
      line-height: 0;

      @include small {
        margin-right: $baseSpacing * 3;
      }

      @include mediumUp() {
        margin-right: $mediumSpacing;
      }
    }

    &_actions {
      justify-content: flex-end;
      align-items: center;
      flex: 1 0 auto;

      margin-left: $normalSpacing;
      display: flex;
      align-items: center;

      @include mediumUp() {
        display: none;
      }

      &_icon {
        width: 20px;
        cursor: pointer;

        i[class*="PQIcon_icon-crosshairs"] {
          color: $pqTextColor;
          font-size: 20px;
        }
        i[class*="PQIcon_icon-bars"] {
          color: $pqTextColor;
          font-size: 20px;
        }
        &:not(:first-child) {
          margin-left: $spacingHeader;
        }
      }
    }
  }

  &_tools {
    display: flex;
    flex-direction: row;
    margin-top: $navBaseHeight + 20px;
    flex: 1 1 100%;
    padding: 0 $spacingHeader;

    &_location {
      width: 50%;
    }

    &_search {
      width: 50%;
      margin-right: $mediumSpacing;
    }

    @include small() {
      &_location {
        display: none;
      }

      &_search {
        width: 100%;
        margin-right: unset;
      }
    }

    @include mediumUp() {
      margin-top: unset;
      flex: 0 0 60%;
      margin-top: 0;
      padding: 0;
    }
  }
}
