@import "../../styles/Palette.module.scss";
@import "../../styles/Variables.module.scss";
@import "../../styles/MediaQueries.module.scss";
@import "../../styles/Font.module.scss";

.searchbar {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-right: $baseSpacing;

  &_autocomplete {
    width: 100%;
    padding: 6px 16px;
    cursor: pointer;
    user-select: none;
    @include mediumDown() {
      width: 97%;
      padding: 5px 0 5px 6px;
    }
  }
  &_loader {
    border: 2px solid $pqLightGray;
    border-top: 2px solid $pqGreen;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  &_input {
    width: 100%;
  }
  &_img {
    width: 55px;
    max-width: 55px;
    margin-right: $baseSpacing;
  }

  &_button {
    &_icon {
      font-size: 15px !important;
      margin-right: calc($baseSpacing / 2);
      @include mediumDown() {
        margin-right: none;
      }
    }

    &_label {
      @include mediumDown() {
        display: none;
      }
    }
  }
}
