@import "../../styles/Palette.module.scss";
@import "../../styles/Variables.module.scss";
@import "../../styles/MediaQueries.module.scss";
@import "../../styles/Mixin.module.scss";

.footer {
  background-color: $pqPrimary;
  color: white;

  &_body {
    max-width: $contentMaxWidth;
    margin-left: auto;
    margin-right: auto;

    padding: $mediumSpacing 0;

    font-weight: 500;

    display: flex;
    flex-wrap: wrap;

    @include mediumDown() {
      padding: $mediumSpacing;
    }

    &_column {
      width: 25%;
      padding-right: $baseSpacing * 3;

      @include small() {
        padding-right: 0;
        width: 50%;
        text-align: center;
      }

      &:first-child {
        @include small() {
          width: 100%;
          margin-bottom: $mediumSpacing;
        }
      }
    }
  }

  &_legal {
    padding: $normalSpacing;
    background: darken($pqPrimary, 10%);

    text-align: center;
    font-size: $smallfontSize;
    font-weight: bold;

    p {
      margin-bottom: 0;

      a,
      span.link {
        cursor: pointer;
        @include anchorColor(white, white);

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &_logo {
    margin-bottom: $normalSpacing;
    line-height: 0;
    width: 50px;
    height: 50px;
  }

  &_socials {
    display: flex;
    flex-wrap: wrap;

    @include small() {
      justify-content: center;
    }

    .social {
      i[class*="pqIcon"] {
        font-size: 20px;
      }

      a {
        @include anchorColor(white, white);
      }

      &:not(:last-of-type) {
        margin-right: 16px;
      }
    }
  }

  &_links-title {
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 30px;
  }

  &_links-list {
    li {
      margin-bottom: $baseSpacing * 3;

      a {
        @include anchorColor(white, white);

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
