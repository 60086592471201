@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?chat1c");
  src: url("../fonts/icomoon.eot?chat1c#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?chat1c") format("truetype"),
    url("../fonts/icomoon.woff?chat1c") format("woff"),
    url("../fonts/icomoon.svg?chat1c#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
