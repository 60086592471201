@import "../../styles/Variables.module.scss";
@import "../../styles/MediaQueries.module.scss";
@import "../../styles/Palette.module.scss";

.locationAutocomplete {
  display: flex;
  flex-direction: row;

  &_vertical {
    flex-direction: column;
  }

  &_input {
    flex-grow: 1;
  }

  &_button {
    &_icon {
      font-size: 15px !important;
      margin-right: calc($baseSpacing / 2);
      @include mediumDown() {
        margin-right: none;
      }
    }

    &_vertical {
      margin-top: $baseSpacing !important;
    }

    &_label {
      @include mediumDown() {
        display: none;
      }
    }
  }
}
