@import "../../styles/Palette.module.scss";
@import "../../styles/Variables.module.scss";
@import "../../styles/MediaQueries.module.scss";

.embla {
  position: relative;
  background-color: $pqBgSecondaryColor;
  margin-left: auto;
  margin-right: auto;

  &__viewport {
    overflow: hidden;

    &.is-draggable {
      cursor: move;
      cursor: grab;
    }

    &.is-dragging {
      cursor: grabbing;
    }
  }

  &__container {
    display: flex;
    user-select: none;
    &__Retailer {
      display: flex;
      user-select: none;
      height: 135px;
    }
  }

  &__slide {
    cursor: pointer;
    position: relative;
    padding-left: 20px;
    min-width: 200px;
    align-self: center;

    &__inner {
      position: relative;
      overflow: hidden;
    }

    &__text {
      margin-top: 12px;
      display: flex;
      align-items: center;
      font-weight: normal;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
    }

    &__footer {
      margin: 12px 0 12px 0;
      font-weight: 700;
      display: flex;

      &__currency {
        font-size: 75%;
      }

      &__price {
        font-size: 1.25em;
      }
    }

    &__img {
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      width: auto;
      min-height: 100%;
      min-width: 100%;
      max-width: none;
      transform: translate(-50%, -50%);
    }
  }

  &__button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    -webkit-user-select: none;
    user-select: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    box-shadow: 2px 2px 4px 0 $pqMediumGray;
    outline: 0;
    cursor: pointer;
    background-color: white;
    color: $pqTextColor;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    padding: 0;
    @include mediumDown {
      background-color: rgba(0, 0, 0, 0.6);
      color: #fff;
      width: 40px;
      height: 40px;
    }

    &_n {
      right: 0;
    }
    &_p {
      left: 0;
    }

    &__svg {
      width: 100%;
      height: 100%;
    }

    &:disabled {
      display: none;
    }
  }

  &_emptySlide {
    width: 50px;
    flex-shrink: 0;
  }
}

// .carousel__image {
// 	&__icon,
// 	&__image {
// 		margin-right: $baseSpacing;
// 	}

// 	&__image {
// 		line-height: 0;
// 		text-align: center;

// 		img {
// 			max-height: 100px;
// 		}
// 	}
// }

// .leaflet {
// 	&_footer {
// 		display: flex;
// 		flex-direction: column;
// 		margin: 0;
// 	}
// 	&_subtitle {
// 		color: $pqMediumDarkGray;
// 		font-weight: 500;
// 	}
// 	&_location {
// 		font-size: 0.8em;
// 		color: $pqTextMuted;
// 		font-weight: 600;
// 	}
// }
